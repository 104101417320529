// import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import {
  BottomWrapper,
  Wrapper,
} from "./style";
import FloorPlanKonva from "./FloorPlanKonva";

const DrawMap = () => {

  const params = useParams();
  const { id } = params;
  // Custom hook for fatching user from DB

  return (
    <Layout>
      <Wrapper>
        <BottomWrapper>
          <FloorPlanKonva mapId={id}/>
        </BottomWrapper>
      </Wrapper>
    </Layout>
  );
};

export default DrawMap;
