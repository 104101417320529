import axios from 'axios';
// Import required modules
// console.log('env', process.env.REACT_APP_API_URL);
const API_URL = `${process.env.REACT_APP_API_URL}/auth/`;
interface LoginData {
    email: string;
    password: string;
}

interface RegisterData extends LoginData {
    username: string;
    email: string;
    password: string;
    password1: string;
    role: string;
}

export const login = async (data: LoginData) => {
    try {
        const response = await axios.post(`${API_URL}signin`, data);
        if (response.data.accessToken) {
            // Save token in local storage or cookie
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};

export const signup = async (data: RegisterData) => {
    try {
        const response = await axios.post(`${API_URL}signup`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = () => {
    // Remove token and user data from local storage
    localStorage.removeItem('user');
};

export const getAuthToken = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user.accessToken;
};

export const getAuthHeader = () => {
     // Get the auth token
     const authToken = getAuthToken();
     // Set up the Authorization header with the Bearer token
     const headers = {
         'Authorization': `Bearer ${authToken}`
     };
    return headers;
};
