// import styled from "styled-components";
import styled, { css } from "styled-components";
// import { ITheme } from "../../shared/types";
import { ITheme, Theme } from "../../shared/types";
import { IUploadStatus } from "./NewMap";

export const Card = styled.div`
  -webkit-box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  background-color: ${(p: ITheme) => p.theme.color.background};
  border-radius: 5px;
`;
export const DataGridWrapper = styled(Card)`
  margin-bottom: 300px;
  width: 100%;
`;
export const DataGridContainer = styled.div`
  height: 600px;
`;
export const Status = styled.div<{ status: "pending" | "active" | "passive" }>`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.status === "passive"
      ? "rgb(201, 75, 75, 0.5)"
      : props.status === "pending"
        ? "rgb(80, 235, 23, 0.2)"
        : "rgb(58, 143, 43, 0.2)"};
  color: ${(props) =>
    props.status === "passive"
      ? "rgb(173, 26, 26)"
      : props.status === "pending"
        ? "rgb(125, 168, 5)"
        : "rgb(29, 87, 19)"};
`;
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .btn {
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px 6px;
    cursor: pointer;
  }
  .viewBtn {
    color: #5949d1;
  }
  .deleteBtn {
    color: rgb(173, 26, 26);
  }
`;
export const AddItem = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .title {
    text-align: center;
    font-size: 20px;
    padding: 12px;
    font-weight: 500;
    color: ${(props: ITheme) => props.theme.color[900]};
  }
  .add {
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid ${(props: ITheme) => props.theme.color[900]};
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    background: ${(props: ITheme) => props.theme.color.background};
    color: ${(props: ITheme) => props.theme.color[900]};
  }
`;

export const LeftPanel = styled.div`
  flex: 2;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .map_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // border-radius: 50%;
    background-color: ${(p: ITheme) => p.theme.color[200]};
  }
`;
export const RightPanel = styled.div`
  flex: 2;
  display: flex;
  align-items: start;
`;

export const EventForm = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 36px;
  .file {
    // width: 40%;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .icon {
    font-size: 36px;
    cursor: pointer;
  }
  .input {
    width: 40%;
  }
  .inputField {
    width: 80%;
    background-color: inherit;
    border: none;
    border-bottom: 1px solid ${(p: ITheme) => p.theme.color[800]};
    padding: 8px 10px;
    font-size: 16px;
    outline: none;
  }
  .inputTitle {
    font-weight: 200;
  }
  .submit {
    width: 50%;
    padding: 6px;
    border: none;
    border-radius: 3px;
    color: ${(p: ITheme) => p.theme.color.background};
    font-size: 18px;
    font-weight: 700;
    background-color: ${(p: ITheme) => p.theme.color[800]};
    cursor: pointer;
  }
`;

interface ISubmitButtonProps {
  theme: Theme;
  uploadStatus?: IUploadStatus;
}
export const SubmitButton = styled.button<ISubmitButtonProps>`
  width: 50%;
  padding: 8px 24px;
  border: none;
  border-radius: 3px;
  color: ${(p: ITheme) => p.theme.color.background};
  font-size: 18px;
  font-weight: 700;
  background-color: ${(p: ITheme) => p.theme.color[800]};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    background-color: ${(p: ITheme) => p.theme.color[500]};
  }
  /* ${(props) => (props.uploadStatus === "UPLOADED" ? css`` : css``)} */
`;


export const Container = styled(Card)`
  display: flex;
  padding: 40px;
  margin-bottom: 200px;
`;
export const HeaderTitle = styled(Card)`
  text-align: center;
  font-size: 26px;
  padding: 12px;
  font-weight: 700;
  color: ${(props: ITheme) => props.theme.color[800]};
`;

export const UploadButton = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TopWrapper = styled.div`
  position: fixed;
  top: 60px;       
  left: 200px;       
  width: calc(100% - 250px); /* Calculate the remaining width */
  z-index: 1000;
  padding: 16px;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  gap: 20px;

  background: ${(props: ITheme) => props.theme.color[200]};

`;
export const BottomWrapper = styled.div`
  -webkit-box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  border-radius: 5px;
  background-color: ${(p: ITheme) => p.theme.color.background};
  padding: 20px;
  .table {
    height: 400px;
  }
  .tableTitle {
    color: ${(p: ITheme) => p.theme.color.cardTitle};
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 16px;
  }
  .canvas-container {
    min-height: 60vh;
  }
  .threejs-mount {
    width: 80vw;
    height: 80vh; /* Use viewport height for responsive design */
  }

`;

export const TopLeftWrapper = styled.div`
  flex: 1;
`;
export const TopRightWrapper = styled.div``;

export const CanvasWrapper = styled.div`
  canvas {
    height: 500px;
  }
`;

export const ButtonWrapper = styled.div`
  // flex: 1;
  .action_button {
    padding : 5px;
    background: '#808080',
    color: '#f1f1f1',
  }
`;

export const GroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .action_button {
    padding : 5px;
    // background: #808080;
    // color: #f1f1f1;
    border-width: 1px;
    border-color: #010101;
  }
  .spec-input {
    display: flex;
    label {
      color: '#808080';
    }
    input {
      text-align: right;
    }
  }
`;

export const StageWrapper = styled.div`
  -webkit-box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  border-radius: 5px;
  background-color: ${(p: ITheme) => p.theme.color.background};
  padding: 0px;
  .table {
    height: 400px;
  }
  .tableTitle {
    color: ${(p: ITheme) => p.theme.color.cardTitle};
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 16px;
  }
  .canvas-container {
    min-height: 60vh;
  }
  .threejs-mount {
    width: 80vw;
    height: 80vh; /* Use viewport height for responsive design */
  }

`;