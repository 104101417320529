import React from "react";
import { FeaturedChartContainer } from "./style";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Stats from "../../stats/Stats";
import EventSelect from "../EventSelect/index";

interface EventSummaryProps {
  data: any; 
  onChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void; 
}


const EventSummary: React.FC<EventSummaryProps> = ({ data, onChangeEvent}) => {
  return (
    <FeaturedChartContainer>
      <div className="heading">
        <h1>Event Summary</h1>
        <MoreHorizIcon className="dots" />
      </div>
      <div className="bottom">
        <EventSelect onSelect={ onChangeEvent }/>
        {/* <div className="progress">
          <div className="progressBar">
            <CircularProgressbar strokeWidth={5} value={70} text={`${70}%`} />
          </div>
        </div> */}
        <div className="progressTitle">{ data?.title }</div>
        <div className="value">{`${data?.start_date || ''}`}</div>
        <div className="value">{ data?.end_date || '' }</div>
        <p className="desc">
          { data?.full_name }
        </p>
        <div className="stats">
          <div className="statsItem">
            <h2>Location</h2>
            <Stats positive={true} value={ data?.location } />
          </div>
          <div className="statsItem">
            <h2>Time</h2>
            <Stats positive={true} value={ data?.time } />
          </div>
        </div>
      </div>
    </FeaturedChartContainer>
  );
};

export default EventSummary;
