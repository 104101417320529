import { useState, useEffect } from 'react';
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { colleaguesColumns } from "../../util/colleaguesTableData";
import {
  ActionContainer,
  AddUser,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { Link, useLocation } from "react-router-dom";
import { getColleagues, deleteColleague, updateColleague } from '../../api/colleaguesService';
import { getEvents } from '../../api/eventsService';
import { setUserPermission } from '../../api/permissionsService';
import { getPermissions } from '../../api/permissionsService';

type ListType = "User" | "Product" | "Order" | "Colleague";

const Permissions = () => {

  const location = useLocation();
  const type: ListType = 'Colleague';

  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  useEffect(() => {
    getEvents().then((items: any) => {
      setEvents(items);
    }).catch((err: any) => {
      console.log(err.message);
    });
    getPermissions().then((items: any) => {
      setPermissions(items);
    }).catch((err: any) => {
      console.log(err.message);
    });
    getColleagues().then((result: any) => {
      setUsers(result);
    }).catch(err => {
      console.log(err.message);
    });
  }, [])

  const handlePermission = async (user_id: string, event_id: string) => {
    console.log('user_id & event_id', user_id, event_id);

    const result = await setUserPermission(user_id, { event_id: event_id });

  }
  const handleCheckboxChange = (event: any) => {
    const { value, checked } = event.target;
    const newSelectedEvents: any = checked
      ? [...selectedEvents, value]
      : selectedEvents.filter(eventId => eventId !== value);

    setSelectedEvents(newSelectedEvents);
    // handlePermission(props.params?.id, newSelectedEvents);
  };
  const actions: GridColDef[] = [
    {
      field: "permission",
      headerName: "Permission",
      width: 230,
      renderCell: (params: any) => {
        const permission: any = permissions.find((item: any) => item.user_id == params.id)
        return (
          <ActionContainer>
            <div className="inputField">
              {events.map((item: any) => permission?.events?.find((ps: any) => ps == item.id) && (
                <div key={item.id}>
                  <input
                    type="checkbox"
                    id={`event_${item.id}`}
                    value={item.id}
                    checked={ true }
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`event_${item.id}`}>{item.title}</label>
                </div>
              ))}
            </div>
          </ActionContainer>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: (params: any) => {
        const permission: any = permissions.find((item: any) => item.user_id == params.id)
        return (
          <ActionContainer>
            <Link
              to={`${location.pathname}/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Edit</button>
            </Link>
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Layout>
      <AddUser>
        <h1 className="title">Permissions for Event</h1>
        {/* <Link
          to={`${location.pathname}/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add Colleague</button>
        </Link> */}
      </AddUser>
      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={users}
            columns={
              colleaguesColumns.concat(actions)
            }
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowHeight={120}
          // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Permissions;
