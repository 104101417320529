import axios from 'axios';
import { getAuthToken, getAuthHeader } from './authService';

const API_URL = `${process.env.REACT_APP_API_URL}/shapes/`;

export const addShape = async (data: any, mapId : string) => {

    // Get the auth token
    const authToken = getAuthToken();
    // Set up the Authorization header with the Bearer token
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        // 'Content-Type': "multipart/form-data",
    };
    const params = {
        map_id : mapId,
     }
    try {
        const response = await axios.post(`${API_URL}`, data, { headers, params });
        if (response.data.accessToken) {
            // Save token in local storage or cookie
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};

export const getShapes = async (mapId : string) => {

    // Set up the Authorization header with the Bearer token
     // Get the auth token
     const authToken = getAuthToken();
     // Set up the Authorization header with the Bearer token
     const headers = {
         'Authorization': `Bearer ${authToken}`,
         // 'Content-Type': "multipart/form-data",
     };
     const params = {
        map_id : mapId,
     }
    try {
        const response = await axios.get(`${API_URL}`, { headers, params });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};

export const getShape = async (id: string) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.get(`${API_URL}${id}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};
export const updateShape = async (data: any) => {

    // Get the auth token
    const authToken = getAuthToken();
    // Set up the Authorization header with the Bearer token
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': "multipart/form-data",
    };
    try {
        const response = await axios.put(`${API_URL}${data.id}`, data, { headers });
        if (response.data) {
            console.log('updated data', response.data);
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};
export const deleteShape = async (id: any) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.delete(`${API_URL}${id}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};

