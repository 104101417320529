import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { applicantsColumns } from "./applicantsTableData";
import { getApplicants, deleteApplicant } from '../../api/applicantsService';
import {
  ActionContainer,
  AddCompany,
  DataGridContainer,
  DataGridWrapper,
} from "./style";

import EventSelect from './EventSelect';
import SearchTorch from '../../components/search/SearchTorch';

// import { db } from "../../firebase";
// import { useData } from "../../hooks/useData";

type ListType = "User" | "Product" | "Order" | "Events" | "Companies" | "Applicants";

const Applicants = () => {
  const location = useLocation();
  // change content on different path
  const type: ListType = "Applicants";
  const [applicants, setApplicants]: any = useState([]);
  const [eventId, setEventId] = useState('');
  useEffect(() => {
    const event_id = localStorage.getItem('applicants_event_id');
    setEventId(event_id || '');
    getApplicants(event_id || '').then((data: any) => {
      const new_data = data.filter((company: any) => company.event.deleted == "no");
      setApplicants(new_data);
    });
    return () => {

    }
  }, []);

  // Custom Hook for fathing data
  // by collection type from Firebase
  // const data = useData(type);

  // Handle deleting user from db
  const handleDelete = async (id: string) => {
    try {
      await deleteApplicant(id);
      const filtered_data: any = applicants.filter((item: any) => item.id !== id);
      setApplicants(filtered_data);
    } catch (err) {
      console.log(err);
    }
  };
  const getApplicantsByEvent = (event_id: any) => {
    localStorage.setItem('applicants_event_id', event_id);
    setEventId(event_id);
    getApplicants(event_id).then(results => {
      const new_data = results.filter((company: any) => company.event.deleted == "no");
      setApplicants(new_data);
    }).catch(err => {
      console.log(err.message);
    })
  }
  const onSearch = async (search_text: string) => {
    // console.log('search text', search_text);
    getApplicants(eventId).then(results => {
      const new_data = results.filter((company: any) => company.event.deleted == "no");
      if (search_text == '') {
        setApplicants(new_data);
      } else {
        const filteredCompanies = new_data.filter((company: any) =>
          company.name.toLowerCase().includes(search_text.toLowerCase())
        );
        setApplicants(filteredCompanies);
      }

    }).catch(err => {
      console.log(err.message);
    })
  }
  // Generic table fields
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <ActionContainer>
            <Link
              to={`${location.pathname}/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link>
            <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button>
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Layout>
      <AddCompany>
        <h1 className="title">Exhibitors</h1>
        <EventSelect onSelect={getApplicantsByEvent} selected={eventId} />
        <SearchTorch onSearch={onSearch} />
        <Link
          to={`${location.pathname}/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add Exhibitor</button>
        </Link>
      </AddCompany>

      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={applicants}
            columns={applicantsColumns.concat(actions)}
            pageSize={10}
            rowsPerPageOptions={[10]}
          // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Applicants;
