import axios from 'axios';
import { getAuthToken, getAuthHeader } from './authService';

const API_URL = `${process.env.REACT_APP_API_URL}/applicants/`;

export const addApplicant = async (data: any) => {

    const headers = getAuthHeader();
    try {
        const response = await axios.post(`${API_URL}`, data, { headers });
        if (response.data) {

        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};

export const getApplicants = async (event_id: string) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    const params = {
        event_id: event_id,
    }
    try {
        const response = await axios.get(`${API_URL}`, { headers, params });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};

export const getApplicant = async (id: string) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.get(`${API_URL}${id}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};
export const updateApplicant = async (data: any) => {

    const headers = getAuthHeader();
    try {
        const response = await axios.put(`${API_URL}${data.id}`, data, { headers });
        if (response.data) {
            console.log('updated data', response.data);
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};
export const deleteApplicant = async (id: string) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.delete(`${API_URL}${id}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};

