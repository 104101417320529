import React, { useEffect, useState } from 'react';
import { ListWrapper } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import ExhibitorList from "./ExhibitorList";
import Layout from "../../components/layout/LayoutSimple";
import {
  TopLeftWrapper,
  TopRightWrapper,
  TopWrapper,
} from "./style";

// import { getCompanys } from '../../api/companysService';
import { getExhibitionData } from '../../api/exhibitionsService';

const Exhibitors = () => {

  const { id }: any = useParams();// map id
  const [exhibition, setExhibition]: any = useState([]);

  useEffect(() => {
    getExhibitionData(id).then((data) => {
      setExhibition(data);
    }).catch((err) => {
      console.log(err.message);
    })
  }, [])

  return (
    <Layout logoImage={`${exhibition.event?.image}`} eventLink={exhibition.event?.link}>
      <TopWrapper>
        <TopLeftWrapper>
          <div className="event-title">{`Exhibitor list for ${exhibition.event?.full_name}`}</div>
        </TopLeftWrapper>
        <TopRightWrapper>
          <Button color="secondary" endIcon={<ExitToAppIcon />} href={exhibition.event?.link}>
            Home
          </Button>
        </TopRightWrapper>
      </TopWrapper>
      <ListWrapper>
        <ExhibitorList data={exhibition.applicants?.filter((item: any)=>item.status == "approved")}/>
      </ListWrapper>
    </Layout>
  );
};

export default Exhibitors;
