import { HTMLInputTypeAttribute } from "react";

export interface FormDataSource {
  id: string;
  label: string;
  type: HTMLInputTypeAttribute;
  placeholder: string;
  readOnly?: boolean;
}

export const formUserInputs: FormDataSource[] = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  // {
  //   id: "displayName",
  //   label: "Full Name",
  //   type: "text",
  //   placeholder: "John Doe",
  // },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "john_doe@mail.com",
    readOnly: true,
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+44012345678",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
    placeholder: "********",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "London",
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "United Kingdom",
  },
];

export const formProductInput: FormDataSource[] = [
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: "category",
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: "price",
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: "stock",
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];
export const formOrderInput: FormDataSource[] = [
  {
    id: "product",
    label: "Product",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: "amount",
    label: "Amount",
    type: "number",
    placeholder: "",
  },
  {
    id: "method",
    label: "Payment Method",
    type: "text",
    placeholder: "",
  },
  {
    id: "status",
    label: "Status",
    type: "text",
    placeholder: "",
  },
];

export const formEventInput: FormDataSource[] = [
  ////Fields : Title	Total Sold	Total Paid	Balance Due	Start Date	End Date	Status	Applicants	Forms	Actions

  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "Input Title",
  },
  {
    id: "full_name",
    label: "Event Full Name",
    type: "text",
    placeholder: "Input Event Full Name",
  },
  {
    id: "location",
    label: "Event Location",
    type: "text",
    placeholder: "Input Location",
  },
  {
    id: "start_date",
    label: "Start Date",
    type: "date",
    placeholder: "",
  },
  {
    id: "end_date",
    label: "End Date",
    type: "date",
    placeholder: "",
  },
  {
    id: "time",
    label: "Event Time",
    type: "time",
    placeholder: "",
  },
  {
    id: "contact_name",
    label: "Contact Name",
    type: "text",
    placeholder: "Input contact name",
  },
  {
    id: "contact_email",
    label: "Contact Email",
    type: "email",
    placeholder: "contact@gmail.com",
  },
  {
    id: "link",
    label: "Link",
    type: "text",
    placeholder: "Input link",
  },
  // {
  //   id: "blank",
  //   label: "",
  //   type: "text",
  //   placeholder: "",
  // },
];

export const formMapInput: FormDataSource[] = [
  ////Fields : Title	Total Sold	Total Paid	Balance Due	Start Date	End Date	Status	Applicants	Forms	Actions

  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Input Name",
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    placeholder: "Input Description",
  },
  // {
  //   id: "width",
  //   label: "Width",
  //   type: "number",
  //   placeholder: "Input Areas",
  // },
  // {
  //   id: "height",
  //   label: "Height",
  //   type: "number",
  //   placeholder: "Input Areas",
  // },
  // {
  //   id: "unit",
  //   label: "Unit",
  //   type: "text",
  //   placeholder: "meter | feet",
  // },
  //{
  //   id: "booths",
  //   label: "Booth count",
  //   type: "number",
  //   placeholder: "5",
  // }, 
];

export const formCompanyInput: FormDataSource[] = [
  ////Fields : Title	Total Sold	Total Paid	Balance Due	Start Date	End Date	Status	Applicants	Forms	Actions

  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Input Name",
  },
  // {
  //   id: "description",
  //   label: "Description",
  //   type: "textarea",
  //   placeholder: "",
  // },
  {
    id: "product_service_keywords",
    label: "Product/Service Keywords",
    type: "text",
    placeholder: "",
  },
 
  {
    id: "website_social_media",
    label: "Website & Social Media",
    type: "text",
    placeholder: "",
  },
  {
    id: "first_name",
    label: "First Name",
    type: "text",
    placeholder: "Input Location",
  },
  {
    id: "last_name",
    label: "Last Name",
    type: "text",
    placeholder: "Input contact name",
  },
  {
    id: "primary_email",
    label: "Primary Email",
    type: "email",
    placeholder: "contact@gmail.com",
  },
  {
    id: "phone",
    label: "phone",
    type: "phone",
    placeholder: "+44123456789",
  },
];

export const formApplicantInput: FormDataSource[] = [
  ////Fields : Title	Total Sold	Total Paid	Balance Due	Start Date	End Date	Status	Applicants	Forms	Actions

  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Input Name",
  },
  
  {
    id: "booth",
    label: "Booth",
    type: "text",
    placeholder: "Input Name",
  },
  {
    id: "product_service_keywords",
    label: "Product/Service Keywords",
    type: "text",
    placeholder: "",
  },
  {
    id: "type",
    label: "Type",
    type: "text",
    placeholder: "",
  },
  {
    id: "approve_status",
    label: "Approve Status",
    type: "text",
    placeholder: "",
  },
  {
    id: "note",
    label: "Note",
    type: "text",
    placeholder: "",
  },


];

