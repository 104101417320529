import { Container, HeaderTitle, SubmitButton, UserForm } from "./style";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
import { Left, Right } from "./style";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { useAppLocation } from "../../hooks/useAppLocation";
import { getProfile, updateProfile } from "../../api/usersService";

interface Props {
  userInputs: FormDataSource[];
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const Profile: React.FC<Props> = ({ userInputs }) => {

  // states
  const [file, setFile] = useState<File | null>(null);
  // upload status for disabling submit button
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");
  const [data, setData]: any = useState<{
    email?: string;
    password?: string;
    image?: string;
    timeStamp?: Date;
  }>({
    timeStamp: new Date(),
  });
  const navigate = useNavigate();
  const location = useAppLocation();

  useEffect(() => {
    if (file) {
      const storageRef = ref(storage, `images/ ${file.name}`);

      uploadBytes(storageRef, file)
        .then(async (snapshot) => {
          const url = await getDownloadURL(storageRef);
          setData((prev: any) => ({ ...prev, image: url }));
          console.log("Image uploaded successfully.");
          setUploadStatus("UPLOADED");
        })
        .catch((error) => console.log(error));
    }
  }, [file]);

  useEffect(() => {
    const fetchData = async () => {
      const resData = await getProfile();
      if (resData) {
        setData(resData);
      }
    }
    fetchData();
  }, []);

  // handle form submiting
  const handleUserSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    try {
      if (data.email && data.username) {
        const result = await updateProfile(data);
      } else {
        console.log("Please enter email and password.");
      }
    } catch (error) {
      console.log("Error creating user: ", error);
    }

  };

  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  // handle form inputs
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  // JSX Return
  return (
    <Layout>
      <HeaderTitle>Profile</HeaderTitle>
      <UserForm
        onSubmit={(e) => handleUserSubmit(e)}
      >
        <Container>
          <Left>
            <div>
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : data?.image
                }
                alt="img"
                className="img"
              />
            </div>
            <div>
              <div className="file">
                <p className="inputTitle">Upload Image:</p>
                <label htmlFor="file">
                  <DriveFolderUploadIcon className="icon" />
                </label>
              </div>
              <input
                onChange={handleFile}
                name="image"
                style={{ display: "none" }}
                type="file"
                id="file"
              />
            </div>
          </Left>
          <Right>
            {/* Input form */}
            <div >
              <div className="inputs">
                {/* Generating inputs from JSON */}
                {userInputs.map((input) => (
                  <div key={input.id} className="input">
                    <label className="inputTitle">{input.label}</label>
                    <br />
                    <input
                      onChange={handleInput}
                      id={input.id}
                      className="inputField"
                      type={input.type}
                      placeholder={input.placeholder}
                      value={data[input.id]}
                      readOnly={input.readOnly}
                    />
                  </div>
                ))}
              </div>
              <div className="button-container">
                {uploadStatus === "UPLOADING" ? (
                  <SubmitButton disabled type="submit" className="submit">
                    Uploading...
                  </SubmitButton>
                ) : (
                  <SubmitButton type="submit" className="submit">Save</SubmitButton>
                )}
              </div>
            </div>
          </Right>
        </Container>
      </UserForm>
    </Layout >
  );
};

export default Profile;
