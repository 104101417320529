import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
export const companiesColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "name",
    headerName: "Name",
    width: 150,
  },
  {
    field: "event",
    headerName: "Event",
    width: 150,
    valueGetter: (params) => params.row.event?.title || ''
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,

  },
  {
    field: "product_service_keywords",
    headerName: "Prodcut/Service Keywords",
    width: 150,
  },
  {
    field: "website_social_media",
    headerName: "Website & Social Media",
    width: 250,

  },
  {
    field: "first_name",
    headerName: "First Name",
    width: 150,
  },
  {
    field: "last_name",
    headerName: "Last Name",
    width: 150,

  },
  {
    field: "primary_email",
    headerName: "Primary Email",
    width: 200,

  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,

  },
];
