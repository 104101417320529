import React, { useContext } from "react";
// import { Link } from "react-router-dom";
import Link from '@mui/material/Link';
import {
  NavbarContainer,
  NavbarItem,
  NavbarItems,
  NavbarWrapper,
  LogoWrapper,
} from "./style";
import { DarkModeContext } from "../../context/darkModeContext";
// import Search from "../search/Search";
import Logo from '../../assets/images/logo.png';

const MenuBar = ({ logoImage, eventLink }: any) => {
  const { isDarkMode, dispatch } = useContext(DarkModeContext);

  return (
    <NavbarWrapper>
      <NavbarContainer>
        <LogoWrapper>
          <Link href={eventLink} style={{ textDecoration: "none" }}>
            {/* <div className="logo">{"TORCH"}</div> */}
            <div className="logo-container">
              <img className="logo-image" src={logoImage ? `${process.env.REACT_APP_SERVER_URL}/${logoImage}` : Logo} alt="TORCH" />
            </div>
          </Link>
        </LogoWrapper>
        <NavbarItems>
          {/* <NavbarItem
            isDarkMode={isDarkMode}
            onClick={() => {
              // if (dispatch) dispatch({ type: "TOGGLE" });
            }}
          >
            <DarkModeOutlinedIcon className="navbarIcon" />
          </NavbarItem>
          <NavbarItem>
            <div className="counter">1</div>
            <NotificationsNoneOutlinedIcon className="navbarIcon" />
          </NavbarItem>
          <NavbarItem>
            <div className="counter">3</div>
            <ChatBubbleOutlineOutlinedIcon className="navbarIcon" />
          </NavbarItem>
          <NavbarItem>
            <ListOutlinedIcon className="navbarIcon" />
          </NavbarItem>
          <NavbarItem>
            <LanguageOutlinedIcon className="navbarIcon" />
            <span>English</span>
          </NavbarItem>
          <NavbarItem>
            <img
              src="/images/noProfile.png"
              alt=""
              className="avatar"
            />
          </NavbarItem> */}
        </NavbarItems>
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default MenuBar;
