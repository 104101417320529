import { Container, Form, Wrapper } from "./style";
import AnimatedBackground from "../../shared/AnimatedBackground";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { login } from "../../api/authService";

// import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth } from "../../firebase";
import OrSeparator from "../../components/custom/OrSeparator/OrSeparator";

const Login = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Save user date in context
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const login_data = await login({ email, password });
    if (!!login_data) {
      // console.log('login data', login_data);
      if (dispatch) dispatch({ type: "LOGIN", payload: login_data });
      navigate("/events");
    } else {
      setError("Wrong credentail.");
    }
  };

  return (
    <>
      <AnimatedBackground />
      <Wrapper>
        <Container>
          <Form onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              placeholder="somename@mail.com"
            />
            <label htmlFor="password">Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              placeholder="********"
            />
            {error && <span className="error">{error} Try again!</span>}
            <button className="btn" type="submit">
              Login
            </button>
            <OrSeparator />
            <button className="btn" onClick={() => navigate('/signup')}>
              Sign Up
            </button>
          </Form>
        </Container>
      </Wrapper>
    </>
  );
};

export default Login;
