import React, { useState, useEffect } from 'react';
import Widget from "../../components/widget/Widget";
import { ChartsWrapper, ListWrapper, WidgetsContainer } from "./style";
import Chart from "../../components/chart/Chart";
import List from '../../components/list/List';
import Layout from "../../components/layout/Layout";
import EventSummary from '../../components/custom/EventSummary';
import CustomChart from '../../components/chart/GoogleChart';

import { getDashaboardData } from '../../api/dashboardService';
import { ticketTableData } from '../../util/ticketTableData';

import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";

import { IWidgetData } from "../../shared/types";
import { getEvents } from '../../api/eventsService';

const Home = () => {

  const [dashboardData, setDashboardData]: any = useState({});

  const [widgets, setWidgets] = useState<IWidgetData[]>([
    {
      type: 'REVENUE',
      value: "0",
      linkTitle: 'Total Revenue',
      stats: { positive: true, value: 0 },
      icon: (
        <AttachMoneyOutlinedIcon
          sx={{
            backgroundColor: "rgb(76, 196, 55, 0.6)",
            borderRadius: "5px;",
            padding: "3px",
          }}
        />
      )
    },
    {
      type: 'TICKETS SOLD',
      value: "0",
      linkTitle: 'Ticket Sold',
      stats: { positive: true, value: 0 },
      icon: (
        <CreditCardOutlinedIcon
          sx={{
            backgroundColor: "rgb(76, 196, 55, 0.6)",
            borderRadius: "5px;",
            padding: "3px",
          }}
        />
      )
    },
    {
      type: 'ORDERS',
      value: "0",
      linkTitle: 'Orders',
      stats: { positive: true, value: 0 },
      icon: (
        <ListAltOutlinedIcon
          sx={{
            backgroundColor: "rgb(76, 196, 55, 0.6)",
            borderRadius: "5px;",
            padding: "3px",
          }}
        />
      )
    },
    {
      type: 'EVENTS',
      value: "0",
      linkTitle: 'Events',
      stats: { positive: true, value: 0 },
      icon: (
        <PersonOutlineIcon
          sx={{
            backgroundColor: "rgb(76, 196, 55, 0.6)",
            borderRadius: "5px;",
            padding: "3px",
          }}
        />
      )
    },

  ]);
  const [eventData, setEventData]: any = useState({});
  const [events, setEvents] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [chartData, setChartData] = useState([
    ["Date", "Sales",],
    ["2024", 0],
  ])
  const [revenueChartData, setRevenueChartData] = useState([
    ["Date", "Revenue"],
    ["2024", 0],
  ])

  useEffect(() => {
    const fetchData = async () => {
      const event_data = await getEvents();
      if (event_data) {
        setEvents(event_data);
      }

      const res = await getDashaboardData();
      if (res?.payload) {
        setDashboardData(res?.payload);
        setTickets(res.payload?.tickets);
        const totalRevenue = res.payload.revenues.reduce((sum: number, item: any) => sum + Number(item.totalAmount), 0);
        setWidgets([
          { ...widgets[0], value: totalRevenue?.toFixed(2) },
          widgets[1],
          widgets[2],
          { ...widgets[3], value: event_data?.length },
        ])
      };


    }
    fetchData();
  }, []);

  const onChangeEvent = async (params: any) => {
    const event: any = events.find((item: any) => item.id == params);
    setEventData(event);
    const eventTickets = dashboardData?.tickets?.filter((item: any) => item.eventId == params);
    setTickets(eventTickets);

    const revenues = dashboardData?.revenues.filter((item: any) => item.eventId == params);
    console.log('revenues', revenues.length);


    let salesChart: any = [["Date", "Sales"]];
    let revenueChart: any = [["Date", "Revenue"]];

    const accumulatedData = new Map();
    const accumulatedRevenueData = new Map();

    revenues.forEach((revenue: any) => {
      const date = revenue.createdAt?.substring(0, 10);
      const sales = revenue.tickets?.reduce((sum: number, ticket: any) => sum + Number(ticket.quantity), 0) ?? 0;
      const totalAmount = Number(revenue.totalAmount);

      if (accumulatedData.has(date)) {
        // If the date is already in the map, update the existing entry
        const existing = accumulatedData.get(date);
        existing[1] += sales; // Sum sales
        // existing[2] += totalAmount; // Sum totalAmount
      } else {
        // If the date is not in the map, add a new entry
        accumulatedData.set(date, [date, sales]);// , totalAmount
      }

      if (accumulatedRevenueData.has(date)) {
        // If the date is already in the map, update the existing entry
        const existing = accumulatedRevenueData.get(date);
        existing[1] += sales; // Sum sales
        // existing[2] += totalAmount; // Sum totalAmount
      } else {
        // If the date is not in the map, add a new entry
        accumulatedRevenueData.set(date, [date, totalAmount]);// , totalAmount
      }

    });
    

    // Step 2: Convert the Map entries to an array
    const resultArray = Array.from(accumulatedData.values());
    setChartData([...salesChart, ...resultArray]);

    const resultArray1 = Array.from(accumulatedRevenueData.values());
    setRevenueChartData([...revenueChart, ...resultArray1]);
  }
  return (
    <Layout>
      {/* WIDGETS */}
      <WidgetsContainer>
        {widgets.map((widget: any, index: number) => (<Widget key={index} {...widget} />))}
      </WidgetsContainer>
      {/* CHARTS */}
      <ChartsWrapper>
        <EventSummary data={eventData} onChangeEvent={onChangeEvent} />
        {/* <Chart title={'Sales & Revenue'} data={chartData} /> */}
        <div className='full-width'>
          <CustomChart title={'Sales'} subtitle={eventData?.title} data={chartData} />
          <CustomChart title={'Revenue'} subtitle={eventData?.title} data={revenueChartData} />
        </div>
      </ChartsWrapper>
      {/* Latest transactions */}
      <ListWrapper>
        <div className="listTitle">Tickets sold</div>
        <List mode="view" list={tickets || []} columns={ticketTableData} hideAction={true} />
      </ListWrapper>
    </Layout>
  );
};

export default Home;
