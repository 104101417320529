import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout/LayoutSimple";

import {
  BottomWrapper,
  TopLeftWrapper,
  TopRightWrapper,
  TopWrapper,
  Wrapper,
} from "./style";
import FloorPlanKonva from "./FloorPlanKonva";
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PrintIcon from '@mui/icons-material/Print';
import LaunchIcon from '@mui/icons-material/Launch';

import { getExhibitionData } from "../../api/exhibitionsService";

const FloorPlan = () => {

  const { id }: any = useParams();// map_id

  const [exhibitionData, setExhibitionData]: any = useState({
    event: {},
    map: { image: 'uploads/images/noimage.png' },
    applicants: [],
    shapes: [],
  });

  useEffect(() => {

    getExhibitionData(id).then(res => {
      // console.log(res);

      const shapeList = res.shapes.map((shape: any) => {
        const bookings = res.applicants.filter((applicant: any) => applicant.booth == shape.no);
        return {
          ...shape,
          company_name: bookings.length > 0 ? bookings[0].company?.name : '',
        }
      });
      // setExhibitionData({
      //   ...exhibitionData,
      //   shapes: shapeList,
      // });

      setExhibitionData({
        ...exhibitionData,
        ...res,
        shapes: shapeList,
      });
    }).catch(err => {
      console.log(err.message);
    });

  }, [id]);

  const onHoverListItem = (item: any) => {
    // console.log('list item', item);
    const shapeList = exhibitionData.shapes.map((shape: any) => {
      return {
        ...shape,
        selected: item.booth == shape.no,
        // company_name: item.company?.name,
      }
    });
    setExhibitionData({
      ...exhibitionData,
      shapes: shapeList,
    });
  }
  const onPrint = async (e: any) => {
    console.log('print function');

  }
  return (
    <Layout logoImage={exhibitionData.event?.image} eventLink={exhibitionData.event?.link}>
      <Wrapper>
        {/* <TopWrapper>
          <TopLeftWrapper>
            <div className="event-title">{exhibitionData.event?.full_name}</div>
          </TopLeftWrapper>
          <TopRightWrapper>
            <Button color="success" endIcon={<LaunchIcon />} href={exhibitionData.event?.link}>
              Home
            </Button>
            <Button color="success" endIcon={<PrintIcon />} onClick={onPrint}>
              Print
            </Button>
          </TopRightWrapper>
        </TopWrapper> */}
        <BottomWrapper>
          <FloorPlanKonva
            event={exhibitionData.event}
            map={exhibitionData.map}
            shapes={exhibitionData.shapes}
            exhibitors={exhibitionData.applicants?.sort((a: any, b: any) => { return a.name.localeCompare(b.name) })}
            onHoverListItem={onHoverListItem}
          />
          {/* <DrawPanel /> */}
        </BottomWrapper>
      </Wrapper>
    </Layout>
  );
};

export default FloorPlan;
