import { Container, HeaderTitle, SubmitButton, EventForm } from "./style";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { LeftPanel, RightPanel, UploadButton } from "./style";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate, useParams } from "react-router-dom";
import { add, getMap, updateMap } from '../../api/mapsService';
import { getEvents } from "../../api/eventsService";
interface Props {
  mapInputs: any;//FormDataSource[];
  actionMode: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const NewMap: React.FC<Props> = ({ mapInputs, actionMode }) => {

  const { id }: any = useParams();
  // states
  const [file, setFile] = useState<File | null>(null);
  // upload status for disabling submit button
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");
  const [data, setData]: any = useState({
    image: `uploads/images/noimage.png`,
  });// Form Data

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [units] = useState([
    { 'id': "meter", 'name' : 'Meter' },
    { 'id': "feet",  "name" : "Feet"  }
  ]);

  useEffect(() => {
    getEvents().then((items: any) => {
      setEvents(items);
    }).catch((err: any) => {
      console.log(err.message);
    })
  }, []);

  useEffect(() => {
    console.log(actionMode);
    if (actionMode == 'edit') {
      getMap(id).then((item) => {
        setData(item);
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [actionMode, id]);
  useEffect(() => {
    if (file) {
      setData({
        ...data,
        image: file.name,
        file: file
      });
      setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    let result = null;
    try {
      if (actionMode === 'edit') {
        result = await updateMap(data);
      } else {
        result = await add(data);
      }
      console.log('result for new map', result);
      navigate('/maps');

    } catch (error: any) {
      console.log(error.message);
    }
  }
  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  // handle form inputs
  const handleInput = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  // JSX Return
  return (
    <Layout>
      <HeaderTitle>Add New Map</HeaderTitle>
      <Container>
        {/* Image upload */}
        <LeftPanel>
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : `${process.env.REACT_APP_SERVER_URL}/${data.image}`
            }
            alt="map image here"
            className="map_img"
          />
        </LeftPanel>
        <RightPanel>
          {/* Input form */}
          <EventForm
            onSubmit={(e) =>
              handleSubmit(e)
            }
          >
            {/* Generating inputs from JSON */}
            {mapInputs.map((input: any) => (
              <div key={input.id} className="input">
                <label className="inputTitle">{input.label}</label>
                <br />
                <input
                  onChange={handleInput}
                  id={input.id}
                  className="inputField"
                  type={input.type}
                  placeholder={input.placeholder}
                  value={data[input.id]}
                />
              </div>
            ))}
            <div className="input">
              <label htmlFor="unit" className="inputTitle">Unit</label>
              <br />
              <select id="unit" className="inputField" value={data.unit} onChange={handleInput}>
                <option value="-1">Select Unit</option>
                {
                  units.map((item: any) => {
                    return (<option key={item.id} value={item.id}>{item.name}</option>)
                  })
                }
              </select>
            </div>
            <div className="input">
              <label htmlFor="event_id" className="inputTitle">Event</label>
              <br />
              <select id="event_id" className="inputField" value={data.event_id} onChange={handleInput}>
                <option value="-1">Select Event</option>
                {
                  events.map((item: any) => {
                    return (<option key={item.id} value={item.id}>{item.title}</option>)
                  })
                }
              </select>
            </div>
            <UploadButton>
              <div className="file">
                <p className="inputTitle">Upload Image:</p>
                <label htmlFor="file">
                  <DriveFolderUploadIcon className="icon" />
                </label>
              </div>
              <input
                onChange={handleFile}
                name="img"
                style={{ display: "none" }}
                type="file"
                id="file"
              />
            </UploadButton>
            {uploadStatus === "UPLOADING" ? (
              <SubmitButton disabled type="submit">
                Uploading...
              </SubmitButton>
            ) : (
              <SubmitButton type="submit">SUBMIT</SubmitButton>
            )}
          </EventForm>
        </RightPanel>
      </Container>
    </Layout>
  );
};

export default NewMap;
