import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog'; // Assuming you're using Material UI for dialogs
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BookingDialogWrapper, Row, LeftLabel, RightText, LogoBig, Website, BoldText } from './style';

function DialogBookingDetails({ isDialogOpen, bookingInfo, handleCloseDialog }: any) {

    const getSiteLink = (link: string)=>{
        if(!link) { return '#'; }
        const arr = link.split(':');
        if(arr.length == 1){
            return 'https://' + link;
        } else {
            return link;
        }
    }
    return (
        <BookingDialogWrapper>
            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                PaperProps={{
                    style: { minWidth: "300px", maxWidth: "80%", minHeight: "140px", position: 'absolute', right: 0, top: 0 }, // Set minimum and maximum width
                }}
            >
                <DialogTitle className='title'>
                    <LogoBig src={`${process.env.REACT_APP_SERVER_URL}/${bookingInfo.company?.logo}`} alt={`${bookingInfo.company?.name} Logo`} />
                </DialogTitle>
                <DialogContent>
                    <Row>
                        <LeftLabel>Booth No</LeftLabel>
                        <RightText>{bookingInfo.booth}</RightText>
                    </Row>
                    <Row>
                        <LeftLabel>Company Name</LeftLabel>
                        <RightText><BoldText>{bookingInfo.company?.name}</BoldText></RightText>
                    </Row>
                    <Row>
                        <LeftLabel>Company Info</LeftLabel>
                        <RightText>{bookingInfo.company?.description}</RightText>
                    </Row>
                    <Row>
                        <LeftLabel>Website</LeftLabel>
                        <RightText> 
                            <Website href={getSiteLink(bookingInfo.company?.website_social_media)} target="_blank" rel="noopener noreferrer">
                                {bookingInfo.company?.website_social_media}
                            </Website>
                        </RightText>
                    </Row>

                </DialogContent>
            </Dialog>
        </BookingDialogWrapper>
    );
}

export default DialogBookingDetails;
