import React from "react";
import MenuBar from "../menubar/MenuBar";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import {
  HomeMainContent,
  HomeMainContentSimpleWrapper,
  MenuBarWrapper,
  SidebarWrapper,
} from "./style";

interface Props {
  children: React.ReactNode;
  logoImage: any,
  eventLink: string,
}

const LayoutSimple: React.FC<Props> = ({ logoImage, eventLink, children }) => {
  return (
    <>
      {/* SIDEBAR */}
      {/* <SidebarWrapper>
        <Sidebar />
      </SidebarWrapper> */}
      <MenuBarWrapper>
        <MenuBar logoImage={logoImage} eventLink={eventLink}/>
      </MenuBarWrapper>
      <HomeMainContentSimpleWrapper>
        <HomeMainContent>{children}</HomeMainContent>
      </HomeMainContentSimpleWrapper>
    </>
  );
};

export default LayoutSimple;
