import styled, { css } from "styled-components";
import { ITheme, Theme } from "../../../shared/types";

export const EventSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
  border: 1px solid ${(p: ITheme) => p.theme.color[300]};
//   width: 100%;
  .inputTitle {
    padding: 5px;
  }
  .inputField {
    background-color: inherit;
    border: none;
    // border-bottom: 1px solid ${(p: ITheme) => p.theme.color[800]};
    padding: 5px 5px;
    font-size: 16px;
    outline: none;
  }
`;