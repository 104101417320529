import { useState, useEffect } from 'react';
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { colleaguesColumns } from "../../util/colleaguesTableData";
import {
  ActionContainer,
  AddUser,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { Link, useLocation } from "react-router-dom";
import { getColleagues, deleteColleague, updateColleague } from '../../api/colleaguesService';

type ListType = "User" | "Product" | "Order" | "Colleague";

const Colleagues = () => {
  
  const location = useLocation();
  const type: ListType = 'Colleague';

  const [users, setUsers] = useState([]);

  useEffect(() => {
    getColleagues().then((result: any) => {
      setUsers(result);
    }).catch(err => {
      console.log(err.message);
    })
  }, [])
  
  const handleAllowBlock = async (id: string, old_status: string) => {
    try {
      const new_status = old_status == 'allowed' ? 'blocked' : 'allowed'
      await updateColleague({
        id: id,
        status: new_status
      });
      const filtered_data : any = users.map((item : any)=>{
        if(item.id == id) {
          return {
            ...item,
            status: new_status
          }
        } else {
          return item
        }

      });
      setUsers(filtered_data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      await deleteColleague(id);
      const filtered_data : any = users.filter((item : any)=>item.id !== id);
      setUsers(filtered_data);
    } catch (err) {
      console.log(err);
    }
  };
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 230,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Link
              to={`${location.pathname}/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Edit</button>
            </Link>
            <button
              onClick={() => handleAllowBlock(params.row.id, params.row.status)}
              className="btn allowButton"
            >
              { params.row.status =="allowed" ? "Block" : "Allow" }
            </button>
            <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button>
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Layout>
      <AddUser>
        <h1 className="title">Colleagues</h1>
        <Link
          to={`${location.pathname}/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add Colleague</button>
        </Link>
      </AddUser>
      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={users}
            columns={
              colleaguesColumns.concat(actions)
            }
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Colleagues;
