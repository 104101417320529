import axios from 'axios';
import { getAuthToken, getAuthHeader } from './authService';

const API_URL = `${process.env.REACT_APP_API_URL}/colleague/`;

export const addColleague = async (data: any) => {

    // Get the auth token
    const authToken = getAuthToken();
    // Set up the Authorization header with the Bearer token
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        // 'Content-Type': "multipart/form-data",
    };
    const params = {
        
    }
    try {
        const response = await axios.post(`${API_URL}`, data, { headers, params });
        if (response.data.accessToken) {
            // localStorage.setItem('colleague', JSON.stringify(response.data));
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};

export const getColleagues = async () => {

    const authToken = getAuthToken();
    const headers = {
        'Authorization': `Bearer ${authToken}`,
    };
    try {
        const response = await axios.get(`${API_URL}`, { headers });
        if (response.data) {

        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        return [];
    }
};

export const getColleague = async (id: string) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.get(`${API_URL}${id}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};
export const updateColleague = async (data: any) => {

    // Get the auth token
    const authToken = getAuthToken();
    // Set up the Authorization header with the Bearer token
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': "multipart/form-data",
    };
    try {
        const response = await axios.put(`${API_URL}${data.id}`, data, { headers });
        if (response.data) {
            console.log('updated data', response.data);
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};
export const deleteColleague = async (id: string) => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.delete(`${API_URL}${id}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};


export const getProfile = async () => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.get(`${API_URL}profile`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};

export const updateProfile = async (data: any) => {

    // Get the auth token
    const authToken = getAuthToken();
    // Set up the Authorization header with the Bearer token
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        // 'Content-Type': "multipart/form-data",
    };
    try {
        const response = await axios.put(`${API_URL}profile`, data, { headers });
        if (response.data) {
            console.log('updated data', response.data);
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return null;
    }
};