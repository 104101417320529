import React, { createContext, useEffect, useReducer } from "react";
import jwt_decode from "jwt-decode";
import AuthReducer from "./AuthReducer";
import { IAuthContext } from "./type";

const checkTokenExpiration = () => {
  try {
    const userItem = localStorage.getItem("user");
    if (!userItem) {
      // console.log('No storage data');
      return null;
      // ... rest of your code
    }
    const user: any = JSON.parse(userItem);
    if (!user) {
      // console.log('No storage data');
      return null;
    }

    const token: string = user.accessToken;
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000; // Convert to Unix timestamp

    // Check if the token is expired
    if (decodedToken.exp < currentTime) {
      // Handle the expiration (e.g., log out the user or refresh the token)
      return null;
    }
    // console.log('Token has expired', decodedToken.exp, currentTime );
    // console.log('Token is valid');
    return user;

  } catch (error) {
    // Handle error (e.g., invalid token)
    console.error('Error checking token expiration:', error);
    return null;
  }
};

const INITIAL_STATE = {
  // user: JSON.parse(localStorage.getItem("user")) || null,
  user: checkTokenExpiration(),
};


export const AuthContext = createContext<IAuthContext>(INITIAL_STATE);

interface IProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<IProviderProps> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    if(state.user){
      localStorage.setItem("user", JSON.stringify(state.user));
    }
  }, [state.user]);

  return (
    <AuthContext.Provider value={{ user: state.user, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
