import axios from 'axios';
import { getAuthToken, getAuthHeader } from './authService';

const API_URL = `${process.env.REACT_APP_API_URL}/dashboard/`;

export const getDashaboardData = async () => {

    // Set up the Authorization header with the Bearer token
    const headers = getAuthHeader();
    try {
        const response = await axios.get(`${API_URL}`, { headers });
        if (response.data) {
            // Save token in local storage or cookie
        }
        return response.data;
    } catch (error: any) {
        console.log(error.message);
        // throw error;
        return [];
    }
};

