import { GridColDef } from "@mui/x-data-grid";//GridValueGetterParams

export const colleaguesColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 160 },
  // {
  //   field: "displayName",
  //   headerName: "Display Name",
  //   width: 180,
  // },

  {
    field: "username",
    headerName: "Username",
    width: 130,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 130,
  },
  {
    field: "address",
    headerName: "Address",
    width: 170,
    // renderCell: (params: GridValueGetterParams) => {
    //   return (<span>{`${params.row.address}, ${params.row.country}`}</span>);
    // },
  },
];
