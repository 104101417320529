import { Container, HeaderTitle, SubmitButton, EventForm } from "./style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
import { Left, Right } from "./style";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { addColleague, getColleague, updateColleague } from '../../api/colleaguesService';

interface Props {
  inputs: FormDataSource[];
  actionMode: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const NewColleague: React.FC<Props> = ({ inputs, actionMode }) => {

  const { id }: any = useParams();
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");
  const [data, setData]: any = useState({
    timeStamp: new Date(),
    image: `uploads/images/noimage.png`,
  });
  const navigate = useNavigate();

  useEffect(() => {
   
  }, []);

  useEffect(() => {
    console.log(actionMode);
    if (actionMode == 'edit') {
      getColleague(id).then((item) => {
        setData(item);
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [actionMode]);

  useEffect(() => {
    if (file) {
      setData({
        ...data,
        image: file.name,
        file: file
      });
      setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    // console.log('form data', data);
    let result = null;
    try {
      if (actionMode === 'edit') {
        result = await updateColleague(data);
      } else {
        result = await addColleague(data);
      }
      navigate('/colleagues');

    } catch (error: any) {
      console.log(error.message);
    }

  };

  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  // handle form inputs
  const handleInput = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;
    if(actionMode =='edit' && id == 'email') return;
    setData({ ...data, [id]: value });
  };

  // JSX Return
  return (
    <Layout>
      <HeaderTitle>{actionMode === 'edit' ? 'Edit Colleague' : 'Add New Colleague'}</HeaderTitle>
      <EventForm
        onSubmit={(e: any) =>
          handleSubmit(e)
        }
      >
        <Container>
          {/* Image upload */}
          <Left>
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : `${process.env.REACT_APP_SERVER_URL}/${data.image}`
              }
              alt="img"
              className="img"
            />
            <div className="file">
              <p className="inputTitle">Upload Image:</p>
              <label htmlFor="file">
                <DriveFolderUploadIcon className="icon" />
              </label>
            </div>
            <input
              onChange={handleFile}
              name="img"
              style={{ display: "none" }}
              type="file"
              id="file"
            />
          </Left>
          <Right>
            {/* Input form */}
            <div>
              <div className="inputs">

                {/* Generating inputs from JSON */}
                {inputs.map((input) => (
                  <div key={input.id} className="input">
                    <label className="inputTitle">{input.label}</label>
                    <br />
                    <input
                      onChange={handleInput}
                      id={input.id}
                      className="inputField"
                      type={input.type}
                      placeholder={input.placeholder}
                      value={data[input.id]}
                      disabled={!input.label}
                    />
                  </div>
                ))}

              </div>
              <div className="button-container">
                {uploadStatus === "UPLOADING" ? (
                  <SubmitButton disabled type="submit">
                    Uploading...
                  </SubmitButton>
                ) : (
                  <SubmitButton type="submit">SUBMIT</SubmitButton>
                )}
              </div>
            </div>
          </Right>
        </Container>
      </EventForm>
    </Layout>
  );
};

export default NewColleague;
