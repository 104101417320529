import { useEffect, useState } from 'react';
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { mapsColumns } from "./mapsTableData";

import {
  ActionContainer,
  AddItem,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { Link, useLocation } from "react-router-dom";
import { getMaps, deleteMap } from "../../api/mapsService";

// import { deleteDoc, doc } from "firebase/firestore";
// import { db } from "../../firebase";
// import { useData } from "../../hooks/useData";

type ListType = "User" | "Product" | "Order" | "Maps";

const Maps = () => {
  const location = useLocation();
  const [maps, setMatps]: any = useState([]);
  // change content on different path
  const type: ListType = "Maps";

  useEffect(() => {
    getMaps().then((data) => {
      const map_data: any = [];
      data.map((item: any)=>{
        map_data.push({
          ...item,
          areas: `${item.booth || 0}`,
        })
      })
      setMatps(map_data);
    });

    return () => {
    }
  }, [])
 
  // Handle deleting user from db
  const handleDelete = async (id: string) => {
    try {
      await deleteMap(id);
      const filtered_data : any = maps.filter((item : any)=>item.id !== id);
      setMatps(filtered_data);
    } catch (err) {
      console.log(err);
    }
  };
  // Generic table fields
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Link
              to={`${location.pathname}/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Edit</button>
            </Link>
            <Link
              to={`${location.pathname}/draw/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Draw</button>
            </Link>
            <Link
              to={`/floorplans/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link>
            <Link
              to={`/exhibitors/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">Exhibitors</button>
            </Link>
            <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button>
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Layout>
      <AddItem>
        <h1 className="title">Maps</h1>
        <Link
          to={`${location.pathname}/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add Map</button>
        </Link>
      </AddItem>
      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={maps}
            columns={mapsColumns.concat(actions)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Maps;
