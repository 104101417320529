import { Container, HeaderTitle, SubmitButton, EventForm } from "./style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
import { Left, Right } from "./style";
import { addColleague, getColleague, updateColleague } from '../../api/colleaguesService';
import { getEvents } from "../../api/eventsService";
import { getPermissions, setUserPermission } from '../../api/permissionsService';

interface Props {
  inputs: FormDataSource[];
  actionMode: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const EditPermission: React.FC<Props> = ({ inputs, actionMode }) => {

  const { id }: any = useParams();
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");
  const [data, setData]: any = useState({}); // colleague data
  const [formdata, setFormdata]: any = useState([]);
  const [permissions, setPermissions]: any = useState([]);
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  useEffect(() => {
    console.log(actionMode);
    getEvents().then((item) => {
      setEvents(item);
    }).catch((err) => {
      console.log('error', err.message);
    });

    getPermissions().then((items: any) => {
      setPermissions(items);
      const filtered = items.find((item: any) => item.user_id == id);
      if (filtered) {
        setFormdata(filtered.events);
      }
    }).catch((err: any) => {
      console.log(err.message);
    });

    if (actionMode == 'edit') {
      getColleague(id).then((item) => {
        setData(item);
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [actionMode]);

  useEffect(() => {
    if (file) {
      setData({
        ...data,
        image: file.name,
        file: file
      });
      setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    // console.log('form data', data);
    
    try {
      const res = await setUserPermission(id, { events: formdata });
      navigate('/permissions');
    } catch (error: any) {
      console.log(error.message);
    }

  };

  // handle form inputs
  const handleInput = async (e: any) => {
    const event_id = e.target.id;
    const value = e.target.checked;
    let filterd = [];
    if (value) {
      filterd = [...formdata, event_id];
      setFormdata([...formdata, event_id])
    } else {
      filterd = formdata?.filter((item: any) => item != event_id);
      setFormdata(filterd);
    }
    // const res = await setUserPermission(id, { events: filterd });
  };
  const isChecked = (event_id: any) => {
    const findone = formdata.find((item: any) => item == event_id);
    return findone;
  }
  // JSX Return
  return (
    <Layout>
      <HeaderTitle>{actionMode === 'edit' ? 'Edit Permission' : 'Add New Permission'}</HeaderTitle>
      <EventForm
        onSubmit={(e: any) =>
          handleSubmit(e)
        }
      >
        <Container>
          {/* Image upload */}
          <Left>
            {/* <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : `${process.env.REACT_APP_SERVER_URL}/${data.image}`
              }
              alt="img"
              className="img"
            /> */}
            <div>
              <div>
                <label className="inputTitle">User Name</label> <br />
                <p>{data.username}</p>
              </div><br />
              <div>
                <label className="inputTitle">Email</label> <br />
                <p>{data.email}</p>
              </div>
              <SubmitButton type="submit" className="submit">
                Save
              </SubmitButton>
            </div>
          </Left>
          <Right>
            {/* Input form */}
            <div className="checkboxs">
              <div className="">
                {events?.map((event: any) => (
                  <div key={event.id} className="event">
                    <input
                      onChange={handleInput}
                      id={event.id}
                      className=""
                      type={'checkbox'}
                      placeholder={'Event'}
                      checked={isChecked(event.id)}
                      disabled={false}
                    />
                    <label className="eventTitle" htmlFor={event.id}>{event.title}</label>
                  </div>
                ))}

              </div>
            </div>
          </Right>
        </Container>
      </EventForm>
    </Layout>
  );
};

export default EditPermission;
