import React from "react";
import { Chart } from "react-google-charts";

interface CustomChartProps {
    title?: string;
    subtitle?: string;
    data?: any;
}
const defaultData = [
    ["Date", "Sales", "Revenue"],
    ["2024", 0, 0],
];

const CustomChart: React.FC<CustomChartProps> = ({ title, subtitle, data }) => {
    const options = {
        chart: {
            title: title || "Company Performance",
            subtitle: subtitle || "Sales, Revenue",
        },
    };
    return (
        <Chart
            chartType="Bar"
            data={data.length > 1 ? data : defaultData }
            width="100%"
            height="250px"
            options={options}
        />
    )
}

export default CustomChart;
