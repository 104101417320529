import styled from "styled-components";
import { ITheme } from "../../shared/types";

export const WidgetsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
export const ChartsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;
export const ListWrapper = styled.div`
  -webkit-box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  display: flex;
  background: ${(p: ITheme) => p.theme.color.background};
  flex-direction: column;
  width: 100%;
  gap: 20px;
  border-radius: 5px;
  .listTitle {
    font-weight: 500;
    font-size: 32px;
    padding: 20px 0 20px 20px;
    color: ${(p: ITheme) => p.theme.color.cardTitle};
  }
`;

export const TableWrapper = styled.div`
  padding: 0px 20px 20px 20px;
  .table {
    background-color: ${(p: ITheme) => p.theme.color.background};
  }
  .tableCell {
    color: ${(p: ITheme) => p.theme.color[900]};
    text-align: center;
    font-size: 14px;
  }
  .cellWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
`;

// export const Status = styled.div<{ status: string }>`
//   padding: 3px 6px;
//   border-radius: 5px;
//   background-color: ${(props) =>
//     props.status === "Pending" ? "#eded72" : "#88b572"};
//   color: ${(props) => (props.status === "Pending" ? "#8a6603" : "#2e6308")};
// `;

//////////////////
// Styled Status component
export const Status = styled.div<{ status: string }>`
  padding: 3px 6px;
  border-radius: 5px;
  background-color: ${(props : any) =>
    props.status === "Pending" ? "#eded72" : "#88b572"};
  color: ${(props : any) => (props.status === "Pending" ? "#8a6603" : "#2e6308")};
`;

// Styled components for other elements
export const ExhibitorCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  width: 80%;
  min-height: 100px;
  margin: 5px auto;
`;

export const Logo = styled.img`
  width: 150px;
  margin-right: 20px;
  align-self: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.h2`
  margin: 0;
  font-size: 1.5em;
  color: #333;
`;

export const Website = styled.a`
  font-size: 1em;
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
`;
export const Event = styled.p`
  font-size: 14px;
  color: #888;
`;

export const Category = styled.p`
  font-size: 0.9em;
  color: #777;
`;

export const Updated = styled.h3`
  margin: 0;
  font-size:12px;
  color: #008000;
  text-align: right;
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

//--------------

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;

`;
export const BottomWrapper = styled.div`
  -webkit-box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  border-radius: 5px;
  background-color: ${(p: ITheme) => p.theme.color.background};
  padding: 20px;

  .canvas-container {
    min-height: 80vh;
  }
`;

export const TopLeftWrapper = styled.div`
  flex: 1;
  .event-title {
    padding : 10px;
    font-size: 24px;
    font-weight: 600;
  }
`;
export const TopRightWrapper = styled.div``;