import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  // ActionContainer,
  AddItem,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { Link, useLocation } from "react-router-dom";

const List = ({ mode, list, columns, hideAction }: any) => {

  const [data, setData]: any = useState([]);
  const location = useLocation();
  const type = "";
  
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 160,
      // renderCell: (params: GridValueGetterParams) => {
      //   return (
      //     <ActionContainer>
      //       {type === "User" && (
      //         <Link
      //           to={`${location.pathname}/${params.row.id}`}
      //           state={{ ...params.row }}
      //           style={{ textDecoration: "none" }}
      //         >
      //           <button className="btn viewBtn">View</button>
      //         </Link>
      //       )}
      //       <button
      //         onClick={() => handleDelete(params.row.id)}
      //         className="btn deleteBtn"
      //       >
      //         Delete
      //       </button>
      //     </ActionContainer>
      //   );
      // },
    },
  ];

  useEffect(() => {
    setData(list);
  }, [list]);
  return (
    <Container>
      {mode == 'edit' && (
        <AddItem>
          <h1 className="title">Add New Item</h1>
          <Link
            to={`${location.pathname}/new`}
            state={{ type }}
            style={{ textDecoration: "none" }}
          >
            <button className="add">Add {type}</button>
          </Link>
        </AddItem>
      )}

      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={data}
            columns={
              hideAction ? columns : columns.concat(actions)
            }
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Container>
  );
};

export default List;
