import { GridColDef } from "@mui/x-data-grid";
//Fields : Title	Total Sold	Total Paid	Balance Due	Start Date	End Date	Status	Applicants	Forms	Actions
export const mapsColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 160 },
  {
    field: "name",
    headerName: "Name",
    width: 250,

  },
  {
    field: "description",
    headerName: "Description",
    width: 400,

  },
  {
    field: "event",
    headerName: "Event",
    width: 250,
    valueGetter: (params) => params.row.event?.title || ''
  },
  {
    field: "areas",
    headerName: "Areas",
    width: 150,
  },
];
