import React, { useEffect, useState } from 'react';
// import { exhibitors_data } from '../../mock/mock_data';

import {
    ExhibitorCard,
    Logo,
    Website,
    Info,
    Name,
    Event,
    Category,
    Updated,
    // Status,
    RowWrapper,
    ColumnWrapper,

} from './style';

import { getCompanys } from '../../api/companysService';
// Update existing Exhibitor component with the new Status styled component
const Exhibitor = ({ exhibitor }: any) => (
    <ExhibitorCard>
        <RowWrapper>
            <Logo src={exhibitor.logo} alt={`${exhibitor.name} Logo`} />
            <Info>
                <Name>{exhibitor.name}</Name>
                <Event>{exhibitor.description || 'No Description yet'}</Event>
                {/* <Category>{exhibitor.product_service_keywords || 'No service yet'}</Category> */}
            </Info>
        </RowWrapper>
        <ColumnWrapper>
            <Website href={exhibitor.website} target="_blank" rel="noopener noreferrer">
                {exhibitor.website_social_media}
            </Website>
            <Updated>{`Booth #${exhibitor.booth}`}</Updated>
        </ColumnWrapper>

    </ExhibitorCard>
);

const ExhibitorList = ({ data }: any) => {

    const [exhibitors, setExhibitors]: any = useState([]);

    useEffect(() => {
        if (!!data) {

            const map_data: any[] = data.map((item: any) => ({
                ...item.company,
                logo: `${process.env.REACT_APP_SERVER_URL}/${item.company.logo}`,
                booth: item.booth,
            }));

            setExhibitors(map_data?.sort((a: any, b: any) => {
                if (a.name.trimStart() === b.name.trimStart()) return 0;
                return a.name.trimStart() > b.name.trimStart() ? 1 : -1;
                // return a.name.localeCompare(b.name)
            }));
        }
    }, [data])

    return (
        <div>
            {exhibitors.map((exhibitor: any) => (
                <Exhibitor key={exhibitor.id} exhibitor={exhibitor} />
            ))}
        </div>
    );
}

export default ExhibitorList;
