// import React from 'react';
// import { exhibitors_data } from '../../mock/mock_data';

import {
  ExhibitorsContainer,
  ExhibitorCard,
  LogoInfo,
  Logo,
  // Website,
  Info,
  Name,
  Category,
  AreaNumber,

} from './style';

// Update existing Exhibitor component with the new Status styled component
const Exhibitor = ({ exhibitor }: any) => (
  <ExhibitorCard >
    <LogoInfo>
      <Logo src={`${process.env.REACT_APP_SERVER_URL}/${exhibitor.company.logo}`} alt={`${exhibitor.name} Logo`} />
      <Info>
        <Name>{exhibitor.company.name}</Name>
        <Category>{exhibitor.category}</Category>
      </Info>
    </LogoInfo>
    <AreaNumber>{exhibitor.booth}</AreaNumber>
  </ExhibitorCard>
);

const ExhibitorList = ({ exhibitors, onHoverItem, onClickItem }: any) => {
  // console.log('exhibitor', exhibitors, typeof exhibitors);
  return (
    <ExhibitorsContainer>
      {exhibitors.map((exhibitor: any) => (
        <div key={exhibitor.id} onClick={(e: any) => { onClickItem(exhibitor) }} onMouseOver={(e: any) => onHoverItem(exhibitor)} onMouseLeave={(e: any) => onHoverItem({})}>
          <Exhibitor key={exhibitor.id} exhibitor={exhibitor} />
        </div>
      ))}
    </ExhibitorsContainer>
  );
}

export default ExhibitorList;
