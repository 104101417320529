import React from 'react';
import './OrSeparator.css'; // Import the CSS file

const OrSeparator: React.FC = () => {
  return (
    <div className="or-separator">
      <span className="line"></span>
      <span className="text">or</span>
      <span className="line"></span>
    </div>
  );
};

export default OrSeparator;
