import React, { useEffect, useState } from "react";
import { NavbarSearch, Results } from "./style";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";


const SearchTorch = ({ onSearch }: any) => {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // console.log(searchQuery);
    
  }, [searchQuery]);

  const handleSearch = async () => {
    onSearch && onSearch(searchQuery);
  };

  return (
    <NavbarSearch>
      <input
        onChange={(e) => setSearchQuery(e.target.value)}
        type="text"
        className="searchInput"
        placeholder="Search..."
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
      <div onClick={handleSearch} className="searchWrapper">
        <SearchOutlinedIcon className="searchIcon navbarIcon " />
      </div>
    </NavbarSearch>
  );
};

export default SearchTorch;
