import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Rect, Circle, Line, Text, Group, Image as KonvaImage, } from 'react-konva';
// import { useImage } from 'react-konva';
import useImage from 'use-image';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import HorizontalIcon from '@mui/icons-material/HorizontalSplit';
import VerticalIcon from '@mui/icons-material/VerticalSplit';

import RectangleIcon from '@mui/icons-material/CropDin';
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import BrushIcon from '@mui/icons-material/Brush';
import TextFieldsIcon from '@mui/icons-material/TextFields';

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import HeightIcon from '@mui/icons-material/Height';
import StraightenIcon from '@mui/icons-material/Straighten';
import NumbersIcon from '@mui/icons-material/Numbers';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// import UpdateIcon from '@mui/icons-material/Update';
// import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
    Wrapper,
    TopWrapper,
    GroupWrapper,
    StageWrapper,
} from "./style";
import { getMap } from '../../api/mapsService';
import { addShape, deleteShape, getShapes } from '../../api/shapesService';

const KonvaShape = ({ data, clickShape, changePosition }: any) => {

    const [position, setPosition]: any = useState({ x: data.x, y: data.y });
    const [stroke] = useState({
        color: data.selected === true ? '#00ff00' : '#ff0000',
        width: 3,
    });
    // Rectangle properties
    const rectWidth = data.width * data.scale;
    const rectHeight = data.height * data.scale;
    const circleRadius = data.diameter / 2.0 * data.scale;
    const points: any = [0,
        0,
        data.direction === "horizontal" ? data.length * data.scale : 0,
        data.direction === "vertical" ? data.length * data.scale : 0
    ];

    // Text settings
    // const textValue = `${data.width} - ${data.height} `;
    const fontSize = 24;

    useEffect(() => {

    }, []);

    // Update the position of the group during the drag event
    const handleDragMove = (e: any) => {
        // console.log('position',e.target.x(), e.target.y() )
        setPosition({
            // Round the x and y values to the nearest integer
            x: e.target.x(),
            y: e.target.y()
        });

        changePosition({
            key: data.key,
            // Round the x and y values to the nearest integer
            x: e.target.x(),
            y: e.target.y(),
        });

    };

    const handleClickGroup = (e: any) => {
        // console.log('Group click', data);
        clickShape(data);
    }

    return (
        <Group
            x={position.x}
            y={position.y}
            draggable
            rotation={data.rotation}
            onDragMove={handleDragMove}
            onDragEnd={handleClickGroup}
            onClick={handleClickGroup}
        >
            {data.type === 'rectangle' && (<>
                <Rect
                    width={rectWidth}
                    height={rectHeight}
                    stroke={data.selected ? '#00ff00' : '#ff0000'}
                    strokeWidth={stroke.width}
                />
                <Text
                    x={0}
                    y={rectHeight / 2 - fontSize / 2} // Adjust for vertical centering
                    width={rectWidth}
                    text={data.no}
                    fontSize={fontSize}
                    fontFamily='Arial'
                    fontWeight="600"
                    fill='#ff00ff'
                    align='center'
                    verticalAlign='middle'
                />
                {/* <Text
                    x={0}
                    y={rectHeight - fontSize - 10} // Adjust for vertical centering
                    width={rectWidth}
                    text={textValue}
                    fontSize={fontSize}
                    fontFamily='Arial'
                    fill='#000000'
                    align='right'
                    verticalAlign='middle'
                /> */}
            </>)}
            {data.type === 'circle' && (<>
                <Circle
                    radius={circleRadius}
                    stroke="black"
                    strokeWidth={4}
                />
            </>)}
            {data.type === 'line' && (<>
                <Line
                    points={points}
                    stroke={stroke.color}
                    strokeWidth={stroke.width}
                />
            </>)}
            {data.type === 'text' && (<>
                <Text
                    x={data.x} // Start text from the left edge of the circle
                    y={data.y - fontSize / 2} // Center text vertically
                    // width={ data.text.length * 10 * data.scale } // The total width of the text is the diameter of the circle
                    text={data.text}
                    fontSize={fontSize}
                    fontFamily="Arial"
                    fill="#000000"
                    align="left"
                />
            </>)}
        </Group>
    );
};

interface BackgroundImageProps {
    src: string; // Type for image source path
    onClick: any,
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ src, onClick }) => {
    const [image, setImage]: any = useState<HTMLImageElement | null>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new window.Image();
        img.src = src;
        img.onload = () => {
            setImage(img); // Once loaded, set the image into state
            // Set the natural dimensions of the image
            setDimensions({
                width: img.naturalWidth,
                height: img.naturalHeight,
            });

        };
    }, [src]); // Effect dependency array

    return (
        <KonvaImage
            image={image}
            width={dimensions.width}  // Set the KonvaImage width to the image's naturalWidth
            height={dimensions.height} // Set the KonvaImage height to the image's naturalHeight
            onClick={onClick}
        />
    );
};

const App = ({ mapId }: any) => {

    const bottomWrapperRef = useRef<HTMLDivElement>(null); // Create a ref for the BottomWrapper div

    const [formData, setFormData] = useState({ 
        id: -1, 
        key: 0, 
        map_id: mapId, 
        no: 1, 
        x: 20, 
        y: 20, 
        width: 10, 
        height: 10, 
        diameter: 5, 
        length: 10, 
        directon: 'horizontal', 
        text: '',
        rotation: 0, 
    });
    const [mapState, setMapState]: any = useState({
        type: 'rectangle',
        scale: 10,
        floorplan: '',
    });
    const [backgroundImage, status] = useImage(mapState.floorplan);
    const [size, setSize] = useState<{ width: number; height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [shapes, setShapes]: any = useState([
        // { type: 'rectangle', no: 20, x: 20, y: 0, width: 20, height: 10, text: "Hi Konva", scale: 10 },
        // { type: 'circle', x: 20, y: 20, radius: 5, text: "Hi Konva", scale: 10 },
        // { type: 'line', x: 20, y: 20, length: 10, direction: 'horizontal', scale: 10 },
        // { type: 'text', x: 20, y: 20, text: 'Hello', scale: 10 },
    ]);
    useEffect(() => {
        // Ensure that the image dimensions are set only when the image status is 'loaded'
        if (status === 'loaded' && backgroundImage) {
            setSize({
                width: backgroundImage.width,
                height: backgroundImage.height,
            });
        }
    }, [status, backgroundImage]);
    useEffect(() => {
        getMap(mapId).then((item) => {
            setMapState({
                ...mapState,
                floorplan: `${process.env.REACT_APP_SERVER_URL}/${item.image}`
            });
        }).catch((err) => {
            console.log('error', err.message);
        });
        getShapes(mapId).then(shapesList => {
            setShapes(shapesList);
        }).catch(err => {
            console.log(err.message);
        })
    }, [mapId]);
    // useEffect(() => {
    //     // Update stage dimensions when the window is resized
    //     const handleResize = () => {
    //         if (bottomWrapperRef.current) {
    //             setSize({
    //                 width: bottomWrapperRef.current.offsetWidth,
    //                 height: bottomWrapperRef.current.offsetHeight,
    //             });
    //         }
    //     };
    //     window.addEventListener('resize', handleResize);
    //     // Set initial dimensions
    //     handleResize();
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    // Handles input change and updates the size state
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevSize) => ({
            ...prevSize,
            [name]: value
        }));
        const new_shapes = shapes.map((item: any) => {
            if (item.selected) {
                return {
                    ...item,
                    [name]: value
                }
            }
            return item;
        });

        setShapes(new_shapes);

    };
    const handleScaleChange = (e: any) => {
        const scale = parseFloat(e.target.value);
        setMapState({
            ...mapState,
            scale: scale,
        });
        const new_shapes = shapes.map((shape: any) => { return { ...shape, scale: scale } });
        setShapes(new_shapes);
    }
    // const handleRotationChange = (e: any) => {
    //     const rotation = parseFloat(e.target.value);
    //     setMapState({
    //         ...mapState,
    //         rotation: rotation,
    //     });
    //     const new_shapes = shapes.map((shape: any) => { return { ...shape, rotation: rotation } });
    //     setShapes(new_shapes);
    // }
    const updateObject = () => {
        const temp = shapes.map((item: any) => {
            if (item.key === formData.key) {
                return {
                    ...item,
                    ...formData,
                    selected: true,
                }
            }
            return item;
        })
        setShapes(temp);
    }
    const deleteObject = async () => {
        // Remove the currentGeometry from the scene
        if(formData.id == -1) {
            console.log('select one booth');
            return;
        }
        try {
            await deleteShape(formData.id);
            const temp = shapes;
            setShapes(temp.filter((item: any) => item.key !== formData.key));

        } catch (e: any) {
            console.log(e.message);
        }

    }
    const addNewObject = () => {
        console.log('formData', mapState.type, formData, mapState);
        const timestamp = new Date().getTime();
        const timestampBase36 = timestamp.toString(36);
        const gridSize = 100;

        setShapes([
            ...shapes,
            {
                ...formData,
                x: size.width / 2 + Math.floor(Math.random() * gridSize),
                y: size.height / 2 + Math.floor(Math.random() * gridSize), // random postion
                ...mapState,
                key: timestampBase36,
                selected: false,
            }]);
    }

    const saveStage = () => {

        const sceneString = JSON.stringify(shapes);
        // Save the string - here we're using localStorage, but you may want to send it to a server
        localStorage.setItem('saveStage', sceneString);

        addShape(shapes, mapId).then((shapes) => {
            console.log(shapes);
        }).catch((err) => {
            console.log(err.message);
        })

    }
    const handleShapeClick = (shape: any) => {
        setFormData({ ...formData, ...shape });
        setMapState({ ...mapState, type: shape.type });

        const new_shapes = shapes.map((item: any) => {
            return {
                ...item,
                selected: item.key === shape.key ? !item.selected : false,
            }
        });

        setShapes(new_shapes);
    }
    const handleBackgroundClick = () => {

        const new_shapes = shapes.map((item: any) => {
            return {
                ...item,
                selected: false,
            }
        });

        setShapes(new_shapes);
    }
    const handlePosition = (position: any) => {
        let temp = shapes;
        temp = temp.map((item: any) => {
            if (item.key === position.key) {
                return {
                    ...item,
                    x: Math.round(position.x),
                    y: Math.round(position.y),
                }
            }
            return item;
        })
        setShapes(temp);
    }
    const creatShape = (shape: any) => {

        return (<KonvaShape key={shape.key} data={shape} clickShape={handleShapeClick} changePosition={handlePosition} />);
    }
    return (
        <Wrapper>
            <TopWrapper>
                <GroupWrapper>
                    <ToggleButtonGroup
                        value={mapState.type}
                        exclusive
                        onChange={(e, newValue): any => setMapState({ ...mapState, type: newValue })}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="rectangle" aria-label="left aligned">
                            <RectangleIcon />
                        </ToggleButton>
                        <ToggleButton value="circle" aria-label="centered">
                            <CircleIcon />
                        </ToggleButton>
                        <ToggleButton value="line" aria-label="centered">
                            <BrushIcon />
                        </ToggleButton>
                        <ToggleButton value="text" aria-label="centered">
                            <TextFieldsIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </GroupWrapper>
                <GroupWrapper>
                    {mapState.type?.toUpperCase()}
                    {mapState.type == 'rectangle' && (<>
                        <TextField
                            name="no"
                            label="Booth No"
                            variant="outlined"
                            value={formData.no} // Bind the input value to the state
                            onChange={handleChange} // Set the onChange handler
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NumbersIcon />
                                    </InputAdornment>
                                ),
                                // Add this to align text to the right
                                inputProps: {
                                    style: { textAlign: 'right' },
                                    type: "number"
                                }
                            }}
                        />
                        <TextField
                            name="width"
                            label="Width"
                            variant="outlined"
                            value={formData.width} // Bind the input value to the state
                            onChange={handleChange} // Set the onChange handler
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AspectRatioIcon />
                                    </InputAdornment>
                                ),
                                // Add this to align text to the right
                                inputProps: {
                                    style: { textAlign: 'right' },
                                    type: "number"
                                }
                            }}
                        />
                        <TextField
                            name="height"
                            label="Height"
                            variant="outlined"
                            value={formData.height} // Bind the input value to the state
                            onChange={handleChange} // Set the onChange handler
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HeightIcon />
                                    </InputAdornment>
                                ),
                                // Add this to align text to the right
                                inputProps: {
                                    style: { textAlign: 'right' },
                                    type: "number"
                                }
                            }}
                        />
                    </>)
                    }

                    {mapState.type == 'circle' && (
                        <TextField
                            name="diameter"
                            label="Diameter"
                            variant="outlined"
                            value={formData.diameter} // Bind the input value to the state
                            onChange={handleChange} // Set the onChange handler
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <RadioButtonUncheckedIcon />
                                    </InputAdornment>
                                ),
                                // Add this to align text to the right
                                inputProps: {
                                    style: { textAlign: 'right' },
                                    type: "number",
                                }
                            }}
                        />
                    )}
                    {mapState.type == 'line' && (
                        <>
                            <TextField
                                name="length"
                                label="Line length"
                                variant="outlined"
                                value={formData.length} // Bind the input value to the state
                                onChange={handleChange} // Set the onChange handler
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <StraightenIcon />
                                        </InputAdornment>
                                    ),
                                    // Add this to align text to the right
                                    inputProps: {
                                        style: { textAlign: 'right' },
                                        type: "number"
                                    }
                                }}
                            />
                            <ToggleButtonGroup
                                value={formData.directon}
                                exclusive
                                onChange={(e, newValue): any => setFormData({ ...formData, directon: newValue })}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="horizontal" aria-label="left aligned">
                                    <HorizontalIcon />
                                </ToggleButton>
                                <ToggleButton value="vertical" aria-label="centered">
                                    <VerticalIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </>
                    )}
                    {mapState.type == 'text' && (
                        <TextField
                            name="text"
                            label="Input Text"
                            variant="outlined"
                            value={formData.text} // Bind the input value to the state
                            onChange={handleChange} // Set the onChange handler
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TextFieldsIcon />
                                    </InputAdornment>
                                ),
                                // Add this to align text to the right
                                inputProps: {
                                    style: { textAlign: 'right' },
                                    type: "number"
                                }
                            }}
                        />
                    )}
                    <TextField
                        name="rotation"
                        label="Rotation"
                        variant="outlined"
                        value={formData.rotation} // Bind the input value to the state
                        onChange={handleChange} // Set the onChange handler
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AspectRatioIcon />
                                </InputAdornment>
                            ),
                            // Add this to align text to the right
                            inputProps: {
                                style: { textAlign: 'right' },
                                type: "number"
                            }
                        }}
                    />
                    <TextField
                        name="scale"
                        label="Scale"
                        variant="outlined"
                        value={mapState.scale} // Bind the input value to the state
                        onChange={handleScaleChange} // Set the onChange handler
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AspectRatioIcon />
                                </InputAdornment>
                            ),
                            // Add this to align text to the right
                            inputProps: {
                                style: { textAlign: 'right' },
                                type: "number"
                            }
                        }}
                    />
                    
                </GroupWrapper>
                <GroupWrapper>
                    Actions:
                    <IconButton className="action_button" onClick={() => addNewObject()}>
                        <AddIcon />
                    </IconButton>
                    {/* <IconButton className="action_button" onClick={() => { updateObject() }}>
                        <TaskAltIcon />
                    </IconButton> */}
                    <IconButton className="action_button" onClick={() => { deleteObject() }}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton className="action_button" onClick={() => { saveStage() }}>
                        <SaveIcon />
                    </IconButton>
                </GroupWrapper>
            </TopWrapper>
            <StageWrapper ref={bottomWrapperRef}>
                <Stage width={size.width} height={size.height}>
                    <Layer>
                        <BackgroundImage src={mapState.floorplan} onClick={handleBackgroundClick} />
                        {shapes.map((shape: any) => creatShape(shape))}
                    </Layer>
                </Stage>
            </StageWrapper>
        </Wrapper >

    );
};

export default App;
