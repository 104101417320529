/*###############*/

export const maps_data: any = [
    {
        id: 1,
        name: 'new map',
        description: 'this is new map',
        areas: 100,
        value: 200,
    }
];

export const companies_data: any = [
    {
        id: 1,
        name: 'test company',
        description: 'IT company',
        product_service_keywords: 'Software',
        website_social: 'no site yet',
        first_name: 'Test',
        last_name: 'User',
        primary_email: 'dev@gmail.com',
        phone: '+044123456789',

    }
];


export const applicants_data: any = [
    {
        id: 1,
        name: 'test company',
        booth: '11',
        product_service_keywords: 'Software',
        type: 'Exhibitor',
        approval_status: 'Approved',

    }
];

export const exhibitors_data = [
    {
        id: 1,
        logo: '/images/noimage.png',
        name: 'Exhibitor 1',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',
    },
    {
        id: 2,
        logo: '/images/noimage.png',
        name: 'Exhibitor 2',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',

    },
    {
        id: 3,
        logo: '/images/noimage.png',
        name: 'Exhibitor 3',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Aproved',
        updated: 'Updated 3 months ago',

    },
    {
        id: 4,
        logo: '/images/noimage.png',
        name: 'Exhibitor 4',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',

    },
    {
        id: 5,
        logo: '/images/noimage.png',
        name: 'Exhibitor 5',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',
    },
    {
        id: 6,
        logo: '/images/noimage.png',
        name: 'Exhibitor 5',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',
    },
    {
        id: 7,
        logo: '/images/noimage.png',
        name: 'Exhibitor 5',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',
    },
    {
        id: 8,
        logo: '/images/noimage.png',
        name: 'Exhibitor 5',
        website: 'https://exhibitorone.com',
        category: 'Technology',
        status: 'Pending',
        updated: 'Updated 3 months ago',
    },
    
];
