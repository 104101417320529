import { GridColDef } from "@mui/x-data-grid";

export const ticketTableData: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "name",
    headerName: "Ticket Name",
    width: 400,
  },
  {
    field: "ticketType",
    headerName: "Ticket Type",
    width: 150,
    // renderCell: (params: GridValueGetterParams) => {
    //   return <span>{`$ ${params.row.price}`}</span>;
    // },
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
  },
  {
    field: "ticketsSold",
    headerName: "Tickets Sold",
    width: 140,
  },
  // {
  //   field: "revenue",
  //   headerName: "Revenue",
  //   width: 140,
  // },
  {
    field: "stockCurr",
    headerName: "Stock",
    width: 140,
  },
];
