// import styled from "styled-components";
import styled, { css } from "styled-components";
// import { ITheme } from "../../shared/types";
import { ITheme, Theme } from "../../shared/types";
import { IUploadStatus } from "./NewApplicant";

export const Card = styled.div`
  -webkit-box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  box-shadow: 5px 5px 15px -3px ${(p: ITheme) => p.theme.color[300]};
  background-color: ${(p: ITheme) => p.theme.color.background};
  border-radius: 5px;
`;
export const DataGridWrapper = styled(Card)`
  margin-bottom: 300px;
  width: 100%;
`;
export const DataGridContainer = styled.div`
  height: 600px;
`;
export const Status = styled.div<{ status: "pending" | "active" | "passive" }>`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.status === "passive"
      ? "rgb(201, 75, 75, 0.5)"
      : props.status === "pending"
      ? "rgb(80, 235, 23, 0.2)"
      : "rgb(58, 143, 43, 0.2)"};
  color: ${(props) =>
    props.status === "passive"
      ? "rgb(173, 26, 26)"
      : props.status === "pending"
      ? "rgb(125, 168, 5)"
      : "rgb(29, 87, 19)"};
`;
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .btn {
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px 6px;
    cursor: pointer;
  }
  .viewBtn {
    color: #5949d1;
  }
  .deleteBtn {
    color: rgb(173, 26, 26);
  }
`;
export const AddCompany = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .title {
    text-align: center;
    font-size: 20px;
    padding: 12px;
    font-weight: 500;
    color: ${(props: ITheme) => props.theme.color[900]};
  }
  .add {
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid ${(props: ITheme) => props.theme.color[300]};
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    background: ${(props: ITheme) => props.theme.color.background};
    color: ${(props: ITheme) => props.theme.color[900]};
  }
`;

export const Left = styled.div`
  flex: 1;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    background-color: ${(p: ITheme) => p.theme.color[200]};
  }
`;
export const Right = styled.div`
  flex: 3;
  display: flex;
`;

export const InputForm = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  .file {
    width: 40%;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .icon {
    font-size: 36px;
    cursor: pointer;
  }
  .input {
    width: 45%;
    padding: 5px;
  }
  .inputField {
    width: 100%;
    background-color: inherit;
    border: none;
    border-bottom: 1px solid ${(p: ITheme) => p.theme.color[800]};
    padding: 8px 10px;
    font-size: 16px;
    outline: none;
  }
  .inputTitle {
    font-weight: 200;
  }
  .full-width {
    width: 100%;
    padding: 5px 5%;
  }
  .descriptionField {
    width: 100%;
    border-width: 0px;
    border-bottom: 1px solid #010101;
  }
  .descriptionField:focus {
    /* Add your focus styles here */
    border-bottom: 2px solid #0000FF; /* example: change bottom border to blue and make it thicker */
    outline: none; /* You might want to remove the default outline */
  }
  .submit {
    width: 50%;
    padding: 6px;
    border: none;
    border-radius: 3px;
    color: ${(p: ITheme) => p.theme.color.background};
    font-size: 18px;
    font-weight: 700;
    background-color: ${(p: ITheme) => p.theme.color[800]};
    cursor: pointer;
  }
`;

interface ISubmitButtonProps {
  theme: Theme;
  uploadStatus?: IUploadStatus;
}
export const SubmitButton = styled.button<ISubmitButtonProps>`
  width: 50%;
  padding: 8px 24px;
  border: none;
  border-radius: 3px;
  color: ${(p: ITheme) => p.theme.color.background};
  font-size: 18px;
  font-weight: 700;
  background-color: ${(p: ITheme) => p.theme.color[800]};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    background-color: ${(p: ITheme) => p.theme.color[500]};
  }
  /* ${(props) => (props.uploadStatus === "UPLOADED" ? css`` : css``)} */
`;

export const Container = styled(Card)`
  display: flex;
  padding: 40px;
  margin-bottom: 200px;
`;
export const HeaderTitle = styled(Card)`
  text-align: center;
  font-size: 26px;
  padding: 12px;
  font-weight: 700;
  color: ${(props: ITheme) => props.theme.color[800]};
`;

export const EventSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border: 1px solid ${(p: ITheme) => p.theme.color[300]};
  .inputTitle {
    padding: 5px;
  }
  .inputField {
    background-color: inherit;
    border: none;
    // border-bottom: 1px solid ${(p: ITheme) => p.theme.color[800]};
    padding: 5px 10px;
    font-size: 16px;
    outline: none;
  }
`;