import { useContext, useState, useEffect } from "react";
import { Container, Form, Wrapper } from "./style";
import AnimatedBackground from "../../shared/AnimatedBackground";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { signup } from "../../api/authService";
import OrSeparator from "../../components/custom/OrSeparator/OrSeparator";

const Singup = () => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState('user');
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  // Save user date in context
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(()=>{
    
    setRole('user');

  }, [])
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if( !username || !email || !password || !password1 ) {
      setError('Input correct information');
      return;
    }
    const login_data = await signup({ username, email, password, password1, role });
    if (!!login_data) {
      console.log('login data', login_data);
      if (dispatch) dispatch({ type: "LOGIN", payload: login_data.user });
      navigate("/");
    } else {
      setError("Signup failed.");
    }
   
  };

  return (
    <>
      <AnimatedBackground />
      <Wrapper>
        <Container>
          <Form onSubmit={handleSubmit}>
            <label htmlFor="username">User Name</label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              name="username"
              id="username"
              placeholder="User name"
            />
            <label htmlFor="email">Email</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              placeholder="somename@mail.com"
            />
            <label htmlFor="password">Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              placeholder="********"
            />
            <label htmlFor="password1">Repeat Password</label>
            <input
              onChange={(e) => setPassword1(e.target.value)}
              type="password"
              name="password1"
              id="password1"
              placeholder="********"
            />
            {error && <span className="error">{error} Try again!</span>}
            <button className="btn" type="submit">
              Sign Up
            </button>
            <OrSeparator />
            <button className="btn" onClick={() => navigate('/login')}>
              Log In
            </button>
          </Form>
        </Container>
      </Wrapper>
    </>
  );
};

export default Singup;
