import { useState, useEffect } from "react";
import { getEvents } from "../../../api/eventsService";
import { EventSelectWrapper } from './style';

const EventSelect = ({ onSelect, selected }: any) => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        getEvents().then(results => {
            setEvents(results);
        }).catch(err => {
            console.log(err.message);
        })
    }, [])

    const handleInput = (e: any) => {
        const value = e.target.value;
        onSelect(value);
    }
    return (<EventSelectWrapper>
        <div className="input">
            {/* <label htmlFor="event" className="inputTitle">Search by Event</label> */}
            <select id="event" className="inputField" onChange={handleInput} value={selected}>
                <option value="">All</option>
                {
                    events.map((item: any) => {
                        return (<option key={item.id} value={item.id}  >{item.title}</option>)
                    })
                }
            </select>
        </div>
    </EventSelectWrapper>)
}

export default EventSelect;